strong {
  font-weight: 500 !important;
}

/* Ant Design Global CSS Override */

.ant-form-explain {
  font-size: 12px !important;
}

.anticon {
  vertical-align: 0 !important;
}

@media (min-width: 992px) {
  *::-webkit-scrollbar-track {
    background-color: #fafafa;
  }

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fafafa;
  }

  *::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.1);
    transition: color 0.2s ease;
  }

  *::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.05);
  }

  *::-webkit-scrollbar-thumb:hover {
    background: rgba(128, 139, 139, 0.8);
  }
}

.sortable-helper {
  z-index: 9999;
}

a {
  color: inherit !important;
}

.gantt_container, .gantt_tooltip {
  font-family: 'Roboto', sans-serif;
}

.gantt_task_line.gantt_task_inline_color {
  border-color: transparent;
}

.gantt_grid_data .gantt_row.odd:hover, .gantt_grid_data .gantt_row:hover {
  background-color: transparent;
}

.gantt_tree_icon.gantt_open {
  background: url(../images/down.svg);
  background-color: #fff;
}

.gantt_tree_icon.gantt_close {
  background: url(../images/up.svg);
  background-color: #fff;
}

.gantt_tree_icon.category {
  background: url(../images/category.svg);
  background-color: #fff;
}

.gantt_tree_icon.project-element {
  background: url(../images/project-element.svg);
  background-color: #fff;
}

.gantt_tree_icon.working-item {
  background: url(../images/working-item.svg);
  background-color: #fff;
}

.gantt_tree_icon.gantt_close,
.gantt_tree_icon.gantt_open,
.gantt_tree_icon.category,
.gantt_tree_icon.project-element,
.gantt_tree_icon.working-item {
  background-repeat: no-repeat;
  background-position: center;
}

.gantt_tree_icon.gantt_close,
.gantt_tree_icon.gantt_open {
  background-size: 50%;
  width: 40px;
  position: relative;
  z-index: 1;
}

.gantt_tree_icon.category,
.gantt_tree_icon.project-element,
.gantt_tree_icon.working-item {
  background-size: 40%;
}

.gantt_tree_icon.category,
.gantt_tree_icon.project-element {
  margin-left: -8px;
}

.gantt_tree_icon.gantt_folder_closed {
  display: none;
}